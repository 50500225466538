let animations = null,
  scene = {};
export const initAnimations = (anime) => {
  animations = anime;
};
export const getAnimations = () => animations;
export const setScene = (obj) => {
  scene = obj;
};
export const getSceneObjects = (name) => {
  return scene.getObjectByName(name);
};
export const startWalking = () => {
  if (animations) {
    stopAllexcept(['walkAction']);
    // animations.idleAction.stop();
    animations.walkAction.play();
    // animations.sofaView.stop();
    // animations.JumpToSofa.stop();
    animations.JumpToSofa.reset();
    animations.sleep.reset();
    // animations.idleAction.crossFadeTo(animations.walkAction, 1000, true);
  }
};
export const stopWalking = () => {
  if (animations) {
    stopAllexcept(['idleAction']);
    animations.idleAction.play();
    // animations.walkAction.stop();
    // animations.sofaView.stop();
    // animations.JumpToSofa.stop();
    animations.JumpToSofa.reset();
    animations.sleep.reset();
    // animations.walkAction.crossFadeTo(animations.idleAction, 1000, true);
  }
};

export const bringSofa = () => {
  if (animations) {
    stopAllexcept(['sofaView', 'JumpToSofa']);
    animations.sofaView.play();
    animations.JumpToSofa.play();
    animations.sleep.reset();
    // animations.walkAction.crossFadeTo(animations.idleAction, 1000, true);
  }
};
export const idealMotion = () => {
  if (animations) {
    stopAllexcept(['idealMotion']);
    animations.idealMotion.play();
    animations.sleep.reset();
  }
};
export const reverseSleep = () => {
  if (animations) {
    stopAllexcept(['sofaView']);
    // animations.sleep.play();
  }
};
export const sleep = () => {
  if (animations) {
    stopAllexcept(['sleep', 'sofaView']);
    animations.sleep.timeScale = 1;
    animations.sofaView.play();
    // animations.JumpToSofa.play();
    animations.sleep.play();
  }
};

const stopAllexcept = (animationNames) => {
  for (let i of Object.keys(animations)) {
    if (animationNames.indexOf(i) === -1) animations[i].stop();
  }
};
