import ContactsData from '../../data/contact';
import styles from './index.module.scss';

const OpenLinks = (link) => window.open(link);

const FloatingContacts = () => {
  return (
    <div className={styles.Conatiner}>
      {ContactsData.map((o) => (
        <img onClick={() => OpenLinks(o.link)} alt={o.name} src={o.image} />
      ))}
    </div>
  );
};

export default FloatingContacts;
