import * as THREE from 'three';

export const initLights = (scene) => {
  const light = new THREE.DirectionalLight(0xffffff, 1);
  scene.add(light);
  light.position.set(0, 11, 20);
  light.castShadow = true;

  const TableLight = new THREE.SpotLight(0xfdf4dc);
  TableLight.position.set(-5, 20, 15);
  TableLight.castShadow = true;
  TableLight.power = 5;
  TableLight.angle = 0.5;
  TableLight.penumbra = 1;
  TableLight.decay = 2;
  TableLight.distance = 200;
  TableLight.shadow.focus = 1;
  // TableLight.target = gltf.scene.getObjectByName('Table');
  TableLight.target.position.set(0, 0, 25);
  // TableLight.target.updateMatrix();
  const spotLightHelper = new THREE.SpotLightHelper(TableLight);
  scene.add(TableLight.target);
  scene.add(TableLight);
  //   scene.add(spotLightHelper);

  const spotLight = new THREE.SpotLight(0xff007f);
  spotLight.position.set(-20, 25, 20);
  spotLight.castShadow = true;
  spotLight.power = 1;
  spotLight.angle = 1;
  spotLight.penumbra = 1;
  spotLight.decay = 2;
  spotLight.distance = 200;
  spotLight.target.position.set(0, 15, 0);
  const spotLight2 = new THREE.SpotLight(0x8000ff);
  spotLight2.position.set(20, 25, 20);
  spotLight2.castShadow = true;
  spotLight2.power = 3.5;
  spotLight2.angle = 1;
  spotLight2.penumbra = 1;
  spotLight2.decay = 2;
  spotLight2.distance = 200;
  spotLight2.target.position.set(0, 15, 0);
  scene.add(spotLight.target);
  scene.add(spotLight);
  scene.add(spotLight2.target);
  scene.add(spotLight2);
  const spotLightHelper1 = new THREE.SpotLightHelper(spotLight);
  const spotLightHelper2 = new THREE.SpotLightHelper(spotLight2);

  //   scene.add(spotLightHelper1, spotLightHelper2);

  const sideLight = new THREE.SpotLight(0xfdf4dc);
  sideLight.position.set(-18, 10, 17);
  sideLight.castShadow = true;
  sideLight.power = 5;
  sideLight.angle = 0.5;
  sideLight.penumbra = 1;
  sideLight.decay = 2;
  sideLight.distance = 200;
  sideLight.shadow.focus = 1;
  // TableLight.target = gltf.scene.getObjectByName('Table');
  sideLight.target.position.set(-15, 0, -10);
  // TableLight.target.updateMatrix();
  const sideLightHelper = new THREE.SpotLightHelper(sideLight);
  scene.add(sideLight.target);
  scene.add(sideLight);
  //   scene.add(sideLightHelper);

  const sideLight2 = new THREE.SpotLight(0xfdf4dc);
  sideLight2.position.set(18, 15, 17);
  sideLight2.castShadow = true;
  sideLight2.power = 5;
  sideLight2.angle = 0.5;
  sideLight2.penumbra = 1;
  sideLight2.decay = 2;
  sideLight2.distance = 200;
  sideLight2.shadow.focus = 1;
  // TableLight.target = gltf.scene.getObjectByName('Table');
  sideLight2.target.position.set(15, 0, -10);
  // TableLight.target.updateMatrix();
  const sideLightHelper2 = new THREE.SpotLightHelper(sideLight2);
  scene.add(sideLight2.target);
  scene.add(sideLight2);
  //   scene.add(sideLightHelper2);
};
