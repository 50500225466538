import {
  bringSofa,
  getSceneObjects,
  idealMotion,
  reverseSleep,
  sleep,
  startWalking,
  stopWalking,
} from './RigSystem';
import * as THREE from 'three';
// let TWEEN = null;
// export const setTween = (obj) => (TWEEN = obj);
let BackObjs = {};
const transformVector = (x, y, z) => ({
  x,
  y,
  z,
});
let pauseRotation = false;
let audioFile = new Audio();
// './assets/Music/slowmotion.mp3'
const config = {
  positions: [
    transformVector(0, 24, 10), //pos 0
    transformVector(0, 18, 6), //pos 1
    transformVector(0, 10, 27), //pos 2
    transformVector(0, 15, 50), //pos 3
    transformVector(0, 15, 50), //pos 4
    transformVector(-13, 20, 17), //pos 5
    transformVector(-16, 10, 17), //pos 6
    transformVector(16, 15, 20), //pos 7
    transformVector(0, 15, 50), //pos 8
    transformVector(-30, 33, 11), //pos 9
  ],
  rotations: [
    transformVector(0, 24, 10), //pos 0
    transformVector(0, 18, 6), //pos 1
    transformVector(0, 10, 27), //pos 2
    transformVector(0, 15, 50), //pos 3
    transformVector(0, 0, 0), //pos 4
    // transformVector(0, 0, 0)
  ],
};
const calculateCameraPosition = (factor) => {
  let index = Math.ceil(factor) - 1;
  if (index < 0) index = 0;
  factor = factor - index;
  const current = config.positions[index];
  const next = config.positions[index + 1];
  return [
    current.x + (next.x - current.x) * factor,
    current.y + (next.y - current.y) * factor,
    current.z + (next.z - current.z) * factor,
  ];
};
const degInRad = (deg) => {
  return (deg * Math.PI) / 180;
};
// b - beginning position
// e - ending position
// i - your current value (0-99)
export const getTween = (b, e, i) => {
  return b + (i / 100) * (e - b);
};
const moveBackObjs = (factor) => {
  let index = Math.ceil(factor) - 1;
  if (index < 0) index = 0;
  factor = factor - index;
  factor *= 100;
  // console.log(getTween(-17, 100, factor * 100));
  BackObjs.mesh.position.set(
    getTween(-17, -470, factor),
    getTween(30, 10, factor),
    -20
  );
  BackObjs.mesh2.position.set(
    getTween(15, 500, factor),
    getTween(24, 14, factor),
    -20
  );
  BackObjs.mesh3.position.set(
    getTween(-13, -660, factor),
    getTween(20, 10, factor),
    -20
  );
};
let first = true;
let pause = false;
export const toggleBack = () => {
  if (pause) audioFile.pause();
  else audioFile.play();
  pause = !pause;
  return pause;
};
export const cameraMotion = (scrollContainer, camera) => {
  if (first) {
    audioFile.play();
    first = false;
  }
  const percent =
    scrollContainer.current.scrollTop / scrollContainer.current.clientHeight;
  camera.position.set(...calculateCameraPosition(percent));
  pauseRotation = false;
  let factor = percent;
  let index = Math.ceil(factor) - 1;
  if (index < 0) index = 0;
  factor = factor - index;
  // if (factor > 0.2) {
  //   scrollContainer.current.scrollTop = (index + 1) * window.innerHeight;
  // }
  if (percent > 4) pauseRotation = true;
  // camera position
  if (percent <= 1) {
    stopWalking();
    moveBackObjs(percent);
  } else if (percent <= 2) {
    idealMotion();
  } else if (percent <= 3) {
    startWalking();
  } else if (percent <= 4) {
    bringSofa(factor);
  } else if (percent <= 7 && percent > 6) {
    reverseSleep();
  } else if (percent <= 9 && percent > 8) {
    sleep();
  }

  factor *= 100;
  if (percent <= 4) {
    camera.setRotationFromAxisAngle(
      new THREE.Vector3(1, 0, 0).normalize(),
      degInRad(0)
    );
    var lookAtVector = new THREE.Vector3(0, 0, -1);
    lookAtVector.applyQuaternion(camera.quaternion).add(camera.position);
  } else if (percent <= 5) {
    // camera.lookAt(getSceneObjects('Table').position);
    camera.lookAt(
      new THREE.Vector3(
        0,
        getTween(15, 0, factor),
        getTween(49, 15.135955810546875, factor)
      )
    );
  } else if (percent <= 6) {
    // camera.lookAt(getSceneObjects('Table001').position);
    camera.lookAt(
      new THREE.Vector3(
        getTween(0, -16.471269607543945, factor),
        getTween(0, 0.022425055503845215, factor),
        getTween(15.135955810546875, 5.214569091796875, factor)
      )
    );
  } else if (percent <= 7) {
    const postions = getSceneObjects('Table002').position;

    // camera.lookAt(postions.x, postions.y + 10, postions.z);
    camera.lookAt(
      new THREE.Vector3(
        getTween(-16.471269607543945, 16.70635986328125, factor),
        getTween(0.022425055503845215, 0.05037581920623779 + 10, factor),
        5.214569091796875
      )
    );
  } else if (percent <= 9) {
    const postions = getSceneObjects('Sofa').position;
    // console.log(postions);
    // camera.lookAt(postions.x, postions.y + 10, postions.z + 10);
    camera.lookAt(
      new THREE.Vector3(
        getTween(16.70635986328125, postions.x, factor),
        getTween(0.05037581920623779 + 10, postions.y + 10, factor),
        getTween(5.214569091796875, postions.z + 10, factor)
      )
    );
  } else if (percent <= 10) {
    const postions = getSceneObjects('Sofa').position;
    // console.log(postions);
    camera.lookAt(postions.x, postions.y + 10, postions.z + 10);
  }
};

const getRange = (value, max, min, total) =>
  (value * (max - min)) / (total - 0) + min;

export const rotateEyes = (e, camera, head) => {
  if (!head || pauseRotation) return;
  const xaxis = window.innerWidth;
  const yaxis = window.innerHeight;
  const xaxisRange = 0.3;
  const yaxisRangeMax = 0.05;
  const yaxisRangeMin = -0.1;
  const xCameratiltMin = 0.03;
  const xCameratiltMax = -0.03;
  const yCameratiltMin = 0.03;
  const yCameratiltMax = -0.03;
  const xaixistilt = getRange(e.clientX, xaxisRange, xaxisRange * -1, xaxis);
  const yaixistilt = getRange(e.clientY, yaxisRangeMax, yaxisRangeMin, yaxis);
  const xCameraTilt = getRange(
    e.clientX,
    xCameratiltMax,
    xCameratiltMin,
    xaxis
  );
  const yCameraTilt = getRange(
    e.clientY,
    yCameratiltMax,
    yCameratiltMin,
    yaxis
  );
  camera.setRotationFromAxisAngle(
    new THREE.Vector3(0, 1, 0).normalize(),
    xCameraTilt
  );
  camera.rotateOnAxis(new THREE.Vector3(1, 0, 0).normalize(), yCameraTilt);
  head.setRotationFromAxisAngle(
    new THREE.Vector3(0, 1, 0).normalize(),
    xaixistilt
  );
  head.rotateOnAxis(new THREE.Vector3(1, 0, 0).normalize(), yaixistilt);
};
export const initBackObjs = (scene) => {
  const sphere = new THREE.SphereGeometry(3, 5, 5);
  const ConeGeometry = new THREE.ConeGeometry(3, 3, 8);
  const Torus = new THREE.TorusGeometry(2, 1, 5, 5);
  const material = new THREE.LineBasicMaterial({
    color: 0xffffff,
    linewidth: 1,
  });
  BackObjs.mesh = new THREE.Line(sphere, material);
  BackObjs.mesh.position.set(-17, 30, -20);
  BackObjs.mesh2 = new THREE.Line(ConeGeometry, material);
  BackObjs.mesh2.position.set(15, 24, -20);
  BackObjs.mesh3 = new THREE.Line(Torus, material);
  BackObjs.mesh3.position.set(-13, 20, -20);
  scene.add(BackObjs.mesh, BackObjs.mesh2, BackObjs.mesh3);

  setInterval(() => {
    BackObjs.mesh2.rotateOnAxis(new THREE.Vector3(0, 1, 1), 0.001);
    BackObjs.mesh.rotateOnAxis(new THREE.Vector3(0, 1, 1), -0.001);
    BackObjs.mesh3.rotateOnAxis(new THREE.Vector3(1, 1, 0), 0.001);
  }, 10);
};
