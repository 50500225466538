import styles from './index.module.scss';
const tech = ['React.js', 'Three.js', 'Blender', 'react-spring'];
const FinalCredits = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.ThankYou}>
        Thank You for taking your time in Knowing about me.
      </div>
      <div className={styles.credits}>
        {/* <div className={styles.heading}>This Website is built using</div>
        {tech.map((o) => (
          <div>{o}</div>
        ))} */}
        <div className={styles.heading}>
          This Website is made with 800+ hours of effort and lots of ❤️
        </div>
      </div>
    </div>
  );
};

export default FinalCredits;
