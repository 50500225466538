import styles from './index.module.scss';
import ProjList from '../../data/projects.json';
import { useCallback, useRef } from 'react';
const Projects = () => {
  const scroll = useRef(null);
  const onScroll = (direction) => {
    if (window.scrollSmooth) {
      return;
    }
    let i = 0;
    if (direction == 'left') {
      if (scroll.current.scrollLeft > 0) {
        window.scrollSmooth = setInterval(() => {
          if (i == 100) {
            clearInterval(window.scrollSmooth);
            window.scrollSmooth = null;
          }
          scroll.current.scrollLeft -= 10;
          i++;
        }, 10);
      }
    } else {
      if (scroll.current.scrollLeft < scroll.current.scrollWidth) {
        window.scrollSmooth = setInterval(() => {
          if (i == 100) {
            clearInterval(window.scrollSmooth);
            window.scrollSmooth = null;
          }
          scroll.current.scrollLeft += 10;
          i++;
        }, 10);
      }
    }
  };
  return (
    <div className={styles.wrapper}>
      <div onClick={() => onScroll('left')} className={styles.lessThan}>
        &lt;
      </div>
      <div onClick={() => onScroll()} className={styles.greaterThan}>
        &gt;
      </div>
      <div ref={scroll} className={styles.projectContainer}>
        {ProjList.map((o) => (
          <div className={styles.projTile}>
            <div className={styles.title}>{o.name}</div>
            <div className={styles.desc}>
              <b>Description:</b>
              <br />
              {o.description}
            </div>
            <div className={styles.techStack}>
              <b>Tech Skills Used:</b>
              <br />
              {o.techStack}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
