import LinkedIn from '../assets/SocialNetwork/linkedin.svg';
import Github from '../assets/SocialNetwork/github.svg';
import Discord from '../assets/SocialNetwork/discord.svg';
// import Insta from '../assets/SocialNetwork/instagram.svg';
import Email from '../assets/SocialNetwork/email.svg';
// import WhatsApp from '../assets/SocialNetwork/whatsapp.svg';
import SAP from '../assets/SocialNetwork/sap.svg';

const ContactsData = [
  {
    name: 'LinkedIn',
    image: LinkedIn,
    link: 'https://www.linkedin.com/in/vigneshkkar/',
    text: 'https://www.linkedin.com/in/vigneshkkar/',
  },
  {
    name: 'GitHub',
    image: Github,
    link: 'https://github.com/Vigneshkkar',
    text: 'https://github.com/Vigneshkkar',
  },
  {
    name: 'Discord',
    image: Discord,
    link: 'https://discord.gg/F7axsGdRqn',
    text: 'Vigneshkkar',
  },
  {
    name: 'Email',
    image: Email,
    link: 'mailto: vigneshkkar@gmail.com',
    text: 'vigneshkkar@gmail.com',
  },
  {
    name: 'SAP',
    image: SAP,
    link: 'https://people.sap.com/vigneshkkar#overview',
    text: 'vigneshkkar Ravichandran',
  },
];

export default ContactsData;
