import ContactsData from '../../data/contact';
import styles from './index.module.scss';
import CV from '../../assets/SocialNetwork/curriculum-vitae.svg';

const ContactMe = () => {
  return (
    <div className={styles.details}>
      <div className={styles.contactContainer}>
        Contact Me:
        {ContactsData.map((o) => (
          <div className={styles.Items}>
            <img alt='' src={o.image} />
            <a target='_blank' href={o.link}>
              {o.text}
            </a>
          </div>
        ))}
      </div>
      <div className={styles.downloadFile}>
        Download PDF version of my Resume
        <img alt='' src={CV} />
        <a href='./assets/Vigneshkkar Resume.pdf' download>
          Vigneshkkar Resume.pdf
        </a>
      </div>
    </div>
  );
};

export default ContactMe;
