import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import styles from './Home.module.scss';
import { useSpring } from '@react-spring/core';
import { initAnimations, setScene } from '../../util/RigSystem';
import {
  cameraMotion,
  initBackObjs,
  rotateEyes,
} from '../../util/AnimationSystem';
import { setPosition, textAnime } from '../../util/TextSystem';
import { initLights } from '../../util/LightSystem';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import Walmart from '../../assets/Global Tech Logos/PNGs/GlobalTech_Spark_green.png';
import Mouse from '../../assets/mouse.svg';
import Projects from '../../components/Projects';
import ContactMe from '../../components/ContactMe';
import FinalCredits from '../../components/FinalCredits';
import FloatingContacts from '../../components/FloatingContacts';
import { Fade, Zoom } from 'react-reveal';
import BackgroundMusic from '../../assets/Music/slowmotion.mp3';
import { ReactComponent as Equalizer } from '../../assets/Equalizer.svg';
import { ReactComponent as Mute } from '../../assets/mute.svg';

// setTween(TWEEN);
const degInRad = (deg) => {
  return (deg * Math.PI) / 180;
};
var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
var renderer = new THREE.WebGLRenderer({
  alpha: true,
  antialias: true,
});

const particlesGeometry = new THREE.BufferGeometry();
const particlesCount = 35000;
let spotLightHelper;

const posArray = new Float32Array(particlesCount * 3);

const Home = () => {
  const [head, sethead] = useState(null);
  const [eyes, seteyes] = useState(null);
  const [state, toggle] = useState(false);
  const [Enter, setEnter] = useState(null);

  const [audio] = useState(new Audio(BackgroundMusic));
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useSpring({
    from: { blink: 0 },
    blink: state ? 1 : 0,
    config: { duration: 250 },
    onChange: (ev) => {
      if (eyes) eyes.map((o) => (o.morphTargetInfluences[0] = ev.value.blink));
    },
  });
  const rendererEl = useRef(null);
  const scrollContainer = useRef(null);
  const logoEl = useRef(null);
  const loader = new GLTFLoader();

  const blinkEyes = () => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 300);
  };

  useEffect(() => {
    let mixer;
    renderer.setSize(window.innerWidth, window.innerHeight);
    rendererEl.current.appendChild(renderer.domElement);
    // initText(scene);
    initBackObjs(scene);
    loader.load(
      './assets/Completed.glb',
      function (gltf) {
        console.log(gltf);
        setScene(gltf.scene);
        const animations = gltf.animations,
          model = gltf.scene;
        mixer = new THREE.AnimationMixer(model);

        const idleAction = mixer.clipAction(animations[3]);
        const walkAction = mixer.clipAction(animations[6]);
        const sofaView = mixer.clipAction(animations[7]);
        const JumpToSofa = mixer.clipAction(animations[2]);
        const idealMotion = mixer.clipAction(animations[1]);
        const sleep = mixer.clipAction(animations[4]);
        sofaView.setLoop(THREE.LoopOnce);
        sofaView.clampWhenFinished = true;
        sofaView.enable = true;
        JumpToSofa.setLoop(THREE.LoopOnce);
        JumpToSofa.clampWhenFinished = true;
        JumpToSofa.enable = true;
        sleep.setLoop(THREE.LoopOnce);
        sleep.clampWhenFinished = true;
        sleep.enable = true;
        // const runAction = mixer.clipAction(animations[1]);
        initAnimations({
          idleAction,
          walkAction,
          sofaView,
          JumpToSofa,
          idealMotion,
          sleep,
        });

        const headBone = gltf.scene.getObjectByName('head').children[0];
        const mesh1 = gltf.scene.getObjectByName('FaceBuilderHead_mesh'),
          mesh2 = gltf.scene.getObjectByName('FaceBuilderHead_mesh_1');
        seteyes([mesh1, mesh2]);
        sethead(headBone);
        // camera.lookAt(headBone);
        // camera.target.position.copy(headBone.getWorldPosition());
        camera.position.set(0, 24, 10);
        // camera.position.set(0, 24, 70);

        scene.add(gltf.scene);
        idleAction.play();

        // scene.add(particlesMesh);

        // headBone.rotateY(THREE.Math.degToRad(10));
        // camera.rotateOnAxis(
        //   new THREE.Vector3(1, 0, 0).normalize(),
        //   degInRad(0)
        // );
        document.getElementById('Loader').style.visibility = 'hidden';
        setEnter(false);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    // const helper = new THREE.DirectionalLightHelper(light, 5);
    // scene.add(helper);

    camera.position.z = 5;
    const clock = new THREE.Clock();
    // const controls = new OrbitControls(camera, renderer.domElement);
    // controls.autoRotate = true;
    initLights(scene);
    setPosition(logoEl);

    const animate = () => {
      // particlesMesh.rotateY = 1;
      requestAnimationFrame(animate);
      // controls.update();
      // spotLightHelper.update();
      // spotLightHelper1.update();
      // spotLightHelper2.update();
      // sideLightHelper.update();
      // sideLightHelper2.update();
      // TWEEN.update();
      var delta = clock.getDelta();
      if (mixer) {
        mixer.update(delta);
      }
      // spotLightHelper.update();

      renderer.render(scene, camera);
    };
    animate();
    return () => {};
  }, []);
  return (
    <>
      {Enter === false ? (
        <div className={styles.EnterClass}>
          <div
            onClick={() => {
              setEnter(true);
              // audio.play();
              setPlaying(true);
            }}>
            Enter
          </div>
        </div>
      ) : (
        <></>
      )}
      <FloatingContacts />
      <div
        ref={scrollContainer}
        onClick={blinkEyes}
        onMouseMove={(e) => rotateEyes(e, camera, head)}
        onScroll={(ev) => {
          cameraMotion(scrollContainer, camera);
          textAnime(scrollContainer, logoEl);
        }}
        className={styles.scrollContainer}>
        <div className={styles.viewport} ref={rendererEl}></div>
        {playing ? (
          <Equalizer
            onClick={() => setPlaying(false)}
            className={styles.Equalizer}
          />
        ) : (
          <Mute onClick={() => setPlaying(true)} className={styles.Equalizer} />
        )}
        <section id='section1'>
          <Fade cascade>
            <Logo
              ref={logoEl}
              className={styles.Logo}
              style={{
                position: 'fixed',
                left: 'calc(50% - 7.5rem)',
                top: 'calc(30% - 7.5rem)',
              }}
            />

            <div className={styles.LogoContainer}>
              <span>Vigneshkkar</span>
              <span className={styles.designation}>
                Full-Stack Software Engineer
              </span>
              <div className={styles.scolltoKnow}>
                <div></div>
                <span>Scroll slowly to know about me.</span>
                <img width='5rem' height='5rem' src={Mouse} alt='' />
              </div>
            </div>
          </Fade>
          {/* <div className='helloBack'>HELLO</div> */}
        </section>
        <section id='section2'>
          <Zoom delay={500}>
            <div className={styles.info}>
              Move mouse and click to play around 🤪
            </div>
            <div className={styles.quoteContainer}>
              {/* <Fade left> */}
              <div>IF YOU CAN DREAM IT,</div>
              {/* </Fade> */}
              {/* <Fade right> */}
              <div>YOU CAN DO IT.</div>
              {/* </Fade> */}
            </div>
          </Zoom>
        </section>
        <section id='section3'>
          <Fade delay={500}>
            <div className={styles.CompanyName}>
              <img src={Walmart} alt='' />

              <div>Currently Working at Walmart Global Tech.</div>
            </div>
          </Fade>
        </section>
        <section id='section4'>
          <Zoom delay={500}>
            <div className={styles.about}>
              <div>
                Pursued my Bachelors in Computer Science and Engineering. <br />
                <br />
                Aspring to become a profound Full-Stack Software Engineer.
                <br />
                <br />
                Great Team Player, always on the hunt for interesting and
                innovative projects and ideas.
              </div>
              <div></div>
            </div>
          </Zoom>
        </section>
        <section id='section5'>
          <Fade delay={500}>
            <Projects />
          </Fade>
        </section>
        <section id='section6'>
          <Fade delay={1000}>
            <div className={styles.openStack}>
              <div></div>
              <div>Open Stack Skills</div>
            </div>
          </Fade>
        </section>
        <section id='section7'>
          <Fade delay={1000}>
            <div className={styles.openStack}>
              <div>SAP Skills</div>
              <div />
            </div>
          </Fade>
        </section>
        <section id='section8'>
          <Fade delay={1000}>
            <div className={styles.hobbies}>
              <div></div>
              <div>
                Hobbies:
                <Fade delay={1000}>
                  <ul>
                    <li>Volleball</li>
                    <li>Baseketball</li>
                    <li>Gaming</li>
                    <li>Thriller Novels</li>
                    <li>Animes</li>
                    <li>Netflix</li>
                  </ul>
                </Fade>
              </div>
            </div>
          </Fade>
        </section>
        <section id='section9'>
          <Fade delay={1000}>
            <ContactMe />
          </Fade>
        </section>
        <section id='section10'>
          <Fade delay={1000}>
            <FinalCredits />
          </Fade>
        </section>
      </div>
    </>
  );
};

export default Home;
