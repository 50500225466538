import { getTween } from './AnimationSystem';
let itop, ileft;

export const setPosition = (Logo) => {
  itop = Logo.current.getBoundingClientRect().top;
  ileft = Logo.current.getBoundingClientRect().left;
};
export const textAnime = (scrollContainer, Logo) => {
  const percent =
    scrollContainer.current.scrollTop / scrollContainer.current.clientHeight;

  let factor = percent;
  let index = Math.ceil(factor) - 1;
  if (index < 0) index = 0;
  factor = factor - index;
  if (percent <= 1) {
    console.log(itop, ileft);
    const left = getTween(ileft, 10, percent * 100);
    const top = getTween(itop, 10, percent * 100);
    const scale = getTween(15, 3, percent * 100);
    // console.log(top);
    Logo.current.style.left = left + 'px';
    Logo.current.style.top = top + 'px';
    Logo.current.style.width = scale + 'rem';
  } else {
    Logo.current.style.left = '10px';
    Logo.current.style.top = '10px';
    Logo.current.style.width = 3 + 'rem';
  }
};
